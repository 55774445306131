import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import comm from './utils/comm'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import './styles/base.css';
import './styles/anime.css';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
// import { ElCollapseTransition } from 'element-plus';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import SQrcode from './components/SQrcode.vue'
import DaterangeSelect from "@/components/DaterangeSelect.vue";
import ZoningCheckboxGroup from "@/components/ZoningCheckboxGroup.vue";
import PsychologyQuestionsTypeSelect from "@/components/PsychologyQuestionsTypeSelect.vue";
import ZoningGroupLabel from "@/components/ZoningGroupLabel.vue";
import Medicaltest from "@/utils/medicaltest";

let vue = createApp(App);
vue.component('SQrcode', SQrcode);
vue.component('QuillEditor', QuillEditor);
vue.component('daterange-select', DaterangeSelect);
vue.component('zoning-checkbox-group', ZoningCheckboxGroup);
vue.component('zoning-group-label', ZoningGroupLabel);
vue.component('psychology-questions-type-select', PsychologyQuestionsTypeSelect);
// vue.component(ElCollapseTransition.name, ElCollapseTransition);

vue.config.errorHandler = function(e, vm, info) {
  console.log(e, vm);
  console.log(info);
};
vue.config.globalProperties.$comm = comm;
vue.config.globalProperties.$medicaltest = Medicaltest;
vue.config.productionTip = false;
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    vue.component(key, component)
}
vue.use(store).use(router).use(ElementPlus, {
    locale: zhCn,
}).mount('#app');
