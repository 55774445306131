export const studentCheckin = [{
		path: '/student-checkin-audit',
		name: 'StudentCheckinAudit',
		component: () => import("../views/pages/checkin/StudentCheckinAudit.vue"),
	}, {
		path: '/student-checkin-query',
		name: 'StudentCheckinQuery',
		component: () => import("../views/pages/checkin/StudentCheckinQuery.vue"),
	}, {
		path: '/student-checkin-total-student',
		name: 'StudentCheckinTotalStudent',
		component: () => import("../views/pages/checkin/StudentCheckinTotalStudent.vue"),
	}, {
		path: '/student-checkin-total-school',
		name: 'StudentCheckinTotalSchool',
		component: () => import("../views/pages/checkin/StudentCheckinTotalSchool.vue"),
	},{
		path: '/popedom-school-total',
		name: 'PopedomSchoolTotal',
		component: () => import("../views/pages/checkin/PopedomSchoolTotal.vue"),
	},{
		path: '/checkin-total',
		name: 'CheckinTotal',
		component: () => import("../views/pages/checkin/CheckinTotal.vue"),
	}, {
		path: '/checkin-totals',
		name: 'CheckinTotals',
		component: () => import("../views/pages/checkin/CheckinTotals.vue"),
	},{
		path: '/Checkin-totals-symptoms',
		name: 'CheckinTotalsSymptoms',
		component: () => import("../views/pages/checkin/CheckinTotalsSymptoms.vue"),
	},{
		path: '/student-checkin-earlywarning',
		name: 'StudentCheckinEarlywarning',
		component: () => import("../views/pages/checkin/StudentCheckinEarlywarning.vue"),
	}, {
		path: '/student-checkin-total-history',
		name: 'StudentCheckinTotalHistory',
		component: () => import("../views/pages/checkin/StudentCheckinTotalHistory.vue"),
	}, {
		path: '/student-checkin-warning',
		name: 'StudentCheckinWarning',
		component: () => import("../views/pages/student/StudentCheckinWarning.vue"),
	}, {
		path: '/student-checkin-warning-disease',
		name: 'StudentCheckinWarningDisease',
		component: () => import("../views/pages/checkin/StudentCheckinWarningDisease.vue"),
	}, {
		path: '/student-checkin-warning-infectious',
		name: 'StudentCheckinWarningInfectious',
		component: () => import("../views/pages/checkin/StudentCheckinWarningInfectious.vue"),
	},{
		path: '/student-classes-suspend',
		name: 'StudentClassesSuspend',
		component: () => import("../views/pages/checkin/StudentClassesSuspend.vue"),
	},
]
