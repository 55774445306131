export default {
  // 弹窗表单标签宽度
  dialogFormLabelWidth: '130',
}
// 扩展方法，日期格式化
Date.prototype.format = function (fmt) {
  fmt = fmt.replace('%Y', 'yyyy').replace('%m', 'MM').replace('%d', 'dd').replace('%T', 'HH:mm:ss').replace('%h', 'hh').replace('%H', 'HH').replace('%i', 'mm').replace('%s', 'ss');
  let o = {
    "y+": this.getFullYear(),							 // 年份
    "y": this.getFullYear() % 100,					 // 年份
    "M+": this.getMonth() + 1,						 // 月份
    "M": this.getMonth() + 1,						 // 月份
    "d+": this.getDate(),									 // 日
    "d": this.getDate(),									 // 日
    "H+": this.getHours(),								 // 小时
    "H": this.getHours(),									 // 小时
    "h+": this.getHours() % 12,						 // 小时
    "h": this.getHours() % 12,						 // 小时
    "m+": this.getMinutes(),							 // 分
    "m": this.getMinutes(),								 // 分
    "s+": this.getSeconds(),							 // 秒
    "s": this.getSeconds(),								 // 秒
    "S+": this.getMilliseconds(),					 // 毫秒
    "S": this.getMilliseconds(),						 // 毫秒
    "E+": this.getDay(),									 // 星期
    "E": this.getDay(),									 // 星期
  }, WW = "星期日,星期一,星期二,星期三,星期四,星期五,星期六".split(','), ww = "周日,周一,周二,周三,周四,周五,周六".split(',');
  for (let k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      if (k == 'y+' || k == 'y') {
        fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 4 ? o[k] : o['y']);
      } else if (k == 'h+' || k == 'h') {
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1 ? o[k] : o[k] > 9 ? o[k] : '0' + o[k]));
        // fmt = fmt.replace(RegExp.$1, (Number(o['H+']) < 13 ? '上午 ' : '下午 ') + (RegExp.$1.length == 1 ? o[k] : o[k] > 9 ? o[k] : '0' + o[k]));
      } else if (k == 'S+' || k == 'S') {
        fmt = fmt.replace(RegExp.$1, o[k]);
      } else if (k == 'E+' || k == 'E') {
        fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? ww[o[k]] : WW[o[k]]);
      } else {
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
      }
    }
  }
  return fmt;
};

Array.prototype.indexOf = function (value) {
  for (let i = 0; i < this.length; i++) {
    if (this[i] == value) return i;
  }
  return -1;
}
Array.prototype.remove = function (value) {
  let index = this.indexOf(value);
  if (index > -1) this.splice(index, 1);
}
Array.prototype.findNameById = function (id) {
  let name = '';
  try {
    name = this.find(item => item.id == id).name;
  } catch { name = ''; }
  return name;
}