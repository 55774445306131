<!-- 日期范围选择器，响应onChange方法，传入两个日期字符串 -->
<template>
    <div class="formitem-daterange" style="width: 87%;">
        <el-date-picker v-model="dates" type="daterange" placeholder="选择日期" value-format="YYYY-MM-DD"
            format="YYYY-MM-DD" style="width: 100%;" @change="change" @visible-change="visibleChange" />
    </div>
</template>

<script>
import { reactive, toRefs, onUpdated } from "vue";
export default {
    name: "DaterangeSelect",
    props: {
        sdate: {
            type: String,
            default: ""
        },
        edate: {
            type: String,
            default: ""
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            dates: ['', ''],
        });
        let allowUpdate = true;
        state.dates = [props.sdate, props.edate];
        const change = (dates) => {
            emit("change", dates[0], dates[1]);
        };
        const visibleChange = (visibility) => {
            allowUpdate = !visibility;
        };
        onUpdated(() => {
            if (allowUpdate) {
                if (state.dates[0] != props.sdate) {
                    state.dates[0] = props.sdate;
                }
                if (state.dates[1] != props.edate) {
                    state.dates[1] = props.edate;
                }
            }
        });
        return {
            ...toRefs(state),
            visibleChange,
            change
        };
    },
}
</script>

<style>

</style>