import { createRouter, createWebHashHistory } from 'vue-router'
import { converAppRoutes } from './appRoute'
import { userbase } from './userBase'
import { gis } from './gis'
import { studentCheckin } from './studentCheckin'
import { studentMedicaltest } from './studentMedicaltest'
import { functionMgr } from './functionMgr'
import { userMgr } from './userMgr'
import { baseSetting } from './baseSetting'
import { psychology } from './psychology'
import { fianco } from './fianco'
import { fiancoSelf } from './fiancoSelf'
import { systemMgr } from './systemMgr'
import { attachments } from './attachments'
import { studentMedicaltestEye } from './studentMedicaltestEye'

const routes = [
  { // 登录
    path: '/signin',
    name: 'Signin',
    component: () => import("../views/Signin.vue")
  },
  { // 主页
    path: '/',
    name: 'Index',
    component: () => import("../views/Index.vue"),
    children: [
			{
				// 驾驶舱
				path: '/data-cockpit',
				name: 'DataCockpit',
				component: () => import("../views/DataCockpit.vue")
			},
			{
				// 驾驶舱2
				path: '/data-cockpit2',
				name: 'DataCockpit2',
				component: () => import("../views/DataCockpit2.vue")
			},
      ...userbase,
      ...gis,
			...studentCheckin,
      ...studentMedicaltest,
      ...functionMgr,
      ...userMgr,
      ...baseSetting,
      ...psychology,
      ...fianco,
      ...fiancoSelf,
      ...systemMgr,
      ...attachments,
      ...studentMedicaltestEye,
    ]
  },

  { // APP登录
    path: '/app-signin',
    name: 'Appsignin',
    component: () => import("../views/Appsignin.vue")
  },
  { // APP主页
    path: '/app-index',
    name: 'Appindex',
    component: () => import("../views/Appindex.vue"),
    meta: {
      keepAlive: false,
    },
    children: [
			{
				// 驾驶舱
				path: '/app-data-cockpit',
				name: 'AppDataCockpit',
				component: () => import("../views/DataCockpit.vue")
			},
			{
				// 驾驶舱2
				path: '/app-data-cockpit2',
				name: 'AppDataCockpit2',
				component: () => import("../views/DataCockpit2.vue")
			},
      ...converAppRoutes(userbase),
      ...converAppRoutes(gis),
      ...converAppRoutes(studentCheckin),
      ...converAppRoutes(studentMedicaltest),
      ...converAppRoutes(functionMgr),
      ...converAppRoutes(userMgr),
      ...converAppRoutes(baseSetting),
      ...converAppRoutes(psychology),
      ...converAppRoutes(fianco),
      ...converAppRoutes(fiancoSelf),
      ...converAppRoutes(systemMgr),
      ...converAppRoutes(attachments),
      ...converAppRoutes(studentMedicaltestEye),
    ]
  },
  { // 预览
    path: '/preview',
    name: 'StudentMedicaltestPreview0',
    component: () => import("../views/StudentMedicaltestPreview.vue"),
    meta: {
      keepAlive: false,
    },
    children: [
      { // 学生体检详细信息预览
        path: '/previewIndex',
        name: 'previewIndex',
        component: () => import("../views/pages/preview/previewIndex.vue"),
      },
      { // 学生体检报告信息预览
        path: '/previewReport',
        name: 'previewReport',
        component: () => import("../views/pages/preview/previewReport.vue"),
      },
    ]
  },
  { // 打印
    path: '/student-qrcode-print',
    name: 'StudentQrcodePrint',
    component: () => import("../views/StudentQrcodePrint.vue"),
  },
  { // 登记卡打印
      path: '/fianco-card-print',
      name: 'fiancoCardPrint',
      component: () => import("../views/pages/fianco/fiancoCardPrint.vue"),
  },
  { // 登记卡打印
      path: '/student-test',
      name: 'studentTest',
      component: () => import("../views/studentTest.vue"),
  }
]

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes
})

export default router