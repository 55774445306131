import md5 from 'js-md5';
import service from './service';
import store from '../store/index';
import router from '../router/index'
import {
	ElMessage,
	ElMessageBox,
	ElLoading
} from "element-plus";
import baseapi from '@/api/baseapi';
import comm from "./comm";
import hazecore from "./hazecore";
import stat from "./static";

let session = sessionStorage,
	local = localStorage;
let loadingInstance = null;
/**
 * 发送get请求
 */
const get = (url, arg1, arg2) => {
	let form = typeof arg2 === 'function' ? arg1 : {};
	let callback = typeof arg2 === 'function' ? arg2 : arg1;
	service.doRequest('GET', "/" + url, form, callback);
}
/**
 * 发送post请求
 */
const post = (url, arg1, arg2) => {
	let form = typeof arg2 === 'function' ? arg1 : {};
	let callback = typeof arg2 === 'function' ? arg2 : arg1;
	service.doRequest('POST', "/" + url, form, callback);
}
/**
 * 发送put请求
 */
const put = (url, arg1, arg2) => {
	let form = typeof arg2 === 'function' ? arg1 : {};
	let callback = typeof arg2 === 'function' ? arg2 : arg1;
	service.doRequest('PUT', "/" + url, form, callback);
}
/**
 * 通用加载分页数据接口
 */
const loadRecords = (module, arg1, arg2) => {
	let form = typeof arg2 === 'function' ? arg1 : {};
	let callback = typeof arg2 === 'function' ? arg2 : arg1;
	service.doRequest('GET', "/" + module + '/list', form, callback);
}
/**
 * 通用save请求接口
 */
const saveRecord = (module, id, form, callback) => {
	service.doRequest('POST', "/" + module + '/save/' + id, form, callback);
}
/**
 * 通用load请求接口
 */
const loadRecord = (module, id, callback, querys) => {
	querys = querys || {};
	let qstr = "",
		keys = Object.keys(querys);
	for (let i = 0; i < keys.length; i++) {
		qstr += (qstr == '' ? '' : '&') + keys[i] + '=' + querys[keys[i]];
	}
	service.doRequest('GET', "/" + module + '/load/' + id + (qstr == '' ? '' : '?' + qstr), {}, callback);
}
/**
 * 通用del请求接口
 */
const deleteRecord = (module, id, callback) => {
	service.doRequest('DELETE', "/" + module + '/delete/' + id, {}, callback);
}
/**
 * 通用switch请求接口
 */
const switchRecord = (module, id, callback) => {
	service.doRequest('POST', "/" + module + '/switch/' + id, {}, callback);
}
/**
 * 通用导入数据接口
 */
const importData = (module, file, sid, callback0) => {
	let sidWhere = '';
	let callback = typeof sid === 'function' ? sid : callback0;
	if (!(typeof sid === 'function') && sid != null && sid > 0) sidWhere = '/' + sid;
	service.doRequest('UPLOAD', "/" + module + '/import' + sidWhere, file, callback);
}
/**
 * 自定义接口
 */
const httpURL = (type, module, info, callback) => {
	service.doRequest(type, "/" + module, info, callback);
}
/* 
	添加新标签页 
	@param that 调用组件的this对象
	@param code 标签页唯一编码
	@param name 标签页(唯一)名称
	@param link 标签页路由地址
*/
const addTabPage = (code, name, link) => {
	if (router) {
		sessionStorage.xpageurl = link;
		router.push(link);
	}
	let tabdata = store.state.tabdata;
	for (let i = 0; i < tabdata.length; i++) {
		let item = tabdata[i];
		if (item.code == code) {
			store.state.curitem = code;
			return;
		}
	}
	store.state.tabdata.push({
		code: code,
		name: name,
		link: link,
		closable: true
	});
	store.state.curitem = code;
}
/* 
	移除标签页
	@param that 调用组件的this对象
	@param code 标签页唯一编码
*/
const remTabPage = (code) => {
	let index = -1,
		pitem, remcur = code == store.state.curitem ? 1 : 0;
	if (!code || code == '') {
		code = store.state.curitem;
		remcur = 1;
	}
	let tabdata = store.state.tabdata;
	for (let i = 0; i < tabdata.length; i++) {
		let item = tabdata[i];
		if (item.code == code) {
			if (remcur == 1) pitem = tabdata[i - 1];
			index = i;
			break;
		}
	}
	if (index > -1) {
		store.state.tabdata.splice(index, 1);
		if (pitem) {
			let link = pitem.link;
			store.state.curitem = pitem.code;
			sessionStorage.xpageurl = link;
			router.push(pitem.link);
		}
	}
}
/* 
	点击标签页(激活并跳转到指定标签页)
	@param code 标签页唯一编码
*/
const clickTabItem = (code) => {
	let citem, tabdata = store.state.tabdata;
	for (let i = 0; i < tabdata.length; i++) {
		let item = tabdata[i];
		if (item.code == code) {
			citem = item;
			break;
		}
	}
	if (citem) {
		let link = citem.link;
		store.state.curitem = citem.code;
		sessionStorage.xpageurl = link;
		router.push(citem.link);
	}
}
/*
	注销当前登录的用户
	@param that 调用组件的this对象
*/
const signout = (isapp) => {
	baseapi.signout((ret) => {
		store.state.tabdata = [{
			code: 'menu-19',
			name: '首页',
			link: '/home',
			closable: false
		}];
		if (isapp) router.replace('/app-signin');
		else router.replace('/signin');
		local.token = '';
		ElMessage(ret.message);
	});
}
/**
 * 判断跳转路由
 * url 跳转地址，不要携带'app-'；
 * lable 标签页名称
 */
const openPage = (lable, url) => {
	if (url.substr(0, 1) == '/') url = url.substr(1);
	if (location.href.indexOf('/app-') > 0) {
		router.replace('/app-' + url);
	} else {
		let e = url.split("?");
		addTabPage(
			"menu-" + e[0],
			lable,
			"/" + url
		);
	}
}

//多少个月的范围区间，0代表当前月份，format传入true则进行格式化
const monthRange = (rangeVal, format) => {
	let range = [];
	if (format) {
		range.push(new Date(new Date().getFullYear(), new Date().getMonth() - rangeVal, 1).format('yyyy-MM-dd'));
		range.push(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).format('yyyy-MM-dd'));
	} else {
		range.push(new Date(new Date().getFullYear(), new Date().getMonth() - rangeVal, 1));
		range.push(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
	}
	return range;
};

//分页控制器（根据屏幕大小自适应计算数据条数）reduce 代表要减去的高度
const tableHeight = (state, reduce) => {
	let heightDao = document.getElementById("subpage");
	let heighe0 = 0,
		heighe1 = 0;
	let sxk = document.querySelector(".el-form");
	if (sxk) {
		heighe0 = sxk.clientHeight;
	}
	let sxk2 = document.querySelector(".el-pagination");
	if (sxk2) {
		heighe1 = sxk2.clientHeight;
	}
	let tableTopDom = document.querySelector(".el-table__header-wrapper");
	let tableTopDomHeight = 0;
	if (tableTopDom) {
		tableTopDomHeight = tableTopDom.clientHeight;
	}
	reduce = heighe0 + heighe1 + tableTopDomHeight;
	let AppIndexDom = document.getElementById("appindex");
	if (heightDao) {
		let heightx = heightDao.offsetHeight;
		if (AppIndexDom) {
			console.log(AppIndexDom.clientHeight);
			// reduce = heighe0 + heighe1 + tableTopDomHeight + 120;
			heightx = AppIndexDom.clientHeight - 80;
		}
		if (heightx != null && heightx > 0) {
			let i = heightx - reduce;
			let sun = Math.floor(i / 42.22);
			if ((sun * 42.22 + tableTopDomHeight) > i && sun > 1) {
				sun = sun - 1;
			}
			i = sun * 42.22 + tableTopDomHeight + 2;
			let existence = 0; // 记录选择条数是否存在
			for (let i = 0; i < stat.pageSizes.length; i++) {
				if (stat.pageSizes[i] == sun) {
					existence = 1;
				}
			}
			let pageSizesx = stat.pageSizes;
			if (existence == 0) {
				pageSizesx = [sun, ...stat.pageSizes];
			}
			var map = {
				i: i,
				sun: sun,
				pageSizes: pageSizesx
			};
			return map;
		}
	}
};
//分页器选择条数时重新计算表格高度
const calculationHeight = (e, v) => {
	let tableTopDom = document.querySelector(".el-table__header-wrapper");
	let tableTopDomHeight = 0;
	if (tableTopDom) {
		tableTopDomHeight = tableTopDom.clientHeight;
	}
	let heightx = 0;
	if (e > v) {
		heightx = v * 42.22 + tableTopDomHeight;
	} else {
		heightx = e * 42.22 + tableTopDomHeight;
	}
	if (heightx - tableTopDomHeight <= 0) {
		heightx = heightx + 42.22 * 2;
	}
	return heightx;
};
const open = (url) => {
	url = url.startsWith(window.roots) ? url : window.roots + url;
	url += (url.indexOf('?') > -1 ? '&' : '?') + 'Authorization=Bearer ' + localStorage.token
	window.open(url);
}
const getParam = (param) => {
	let querys = location.href.substring(location.href.indexOf('?') + 1),
		items = querys.split('&');
	for (let i = 0; i < items.length; i++) {
		let item = items[i].split('=');
		if (item.length > 1 && item[0] == param) return item[1];
	}
	return '';
}
const showLoading = (text) => {
	if (text == null || text == "") text = "加载中，请稍后~";
	loadingInstance = ElLoading.service({
		text: text,
		fullscreen: true
	});
}
const hideLoading = () => {
	loadingInstance.close();
}
const filterZoings = (countyid) => {
	return stat.zoning.filter(x => countyid == null || countyid == 0 || x.id == countyid);
}
//判断是否不是空字符串
const notEmptyStr = (str) => {
	return str != null && str != "" && str != "null" && str != "undefined" && str != undefined;
}
//判断是否是空字符串
const isEmptyStr = (str) => {
	return str == null || str == "" || str == "null" || str == "undefined" || str == undefined;
}
/* 
  拼接地址栏参数 
  @param param0 地址 (String)
  @param param1 需要循环拼接的对象 (Object)
*/
const splicingAddress = (param0, param1) => {
	let uri = "";
	let object = {};
	if (typeof(param0) == "string") {
		uri = param0;
		object = param1;
	} else {
		uri = param1;
		object = param0;
	}
	for (let key in object) {
		if (key == null) continue;
		if (uri.indexOf("?") == -1) uri += "?" + key + "=" + object[key];
		else uri += "&" + key + "=" + object[key];
	}
	return uri;
}
export default {
	md5,
	get,
	post,
	put,
	open,
	loadRecords,
	saveRecord,
	loadRecord,
	deleteRecord,
	switchRecord,
	importData,
	httpURL,
	session,
	local,
	store,
	router,
	ElMessage,
	ElMessageBox,
	comm,
	addTabPage,
	remTabPage,
	clickTabItem,
	signout,
	hazecore,
	openPage,
	getParam,
	monthRange,
	tableHeight,
	calculationHeight,
	pageSizes: stat.pageSizes,
	zoning: stat.zoning,
	zoningIds: stat.zoningIds,
	studentTypes: stat.studentTypes,
	color: stat.color,
	showLoading,
	hideLoading,
	filterZoings,
	notEmptyStr,
	isEmptyStr,
	splicingAddress,
}