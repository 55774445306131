/*心理测评管理 */
export const psychology = [{ // 心理测评题目分类
    path: '/psychology-questions-type',
    name: 'PsychologyQuestionsType',
    component: () => import("../views/pages/psychology/PsychologyQuestionsType.vue"),
},
{ // 心理测评题目
    path: '/question-bank',
    name: 'questionBank',
    component: () => import("../views/pages/psychological/questionBank.vue"),
},
{ // 心理测评题问卷
    path: '/psychology-exam',
    name: 'PsychologyExam',
    component: () => import("../views/pages/psychology/PsychologyExam.vue"),
},
{ // 心理测评分析指标
    path: '/psychology-indicator',
    name: 'PsychologyIndicator',
    component: () => import("../views/pages/psychology/PsychologyIndicator.vue"),
},
{ // 心理测评分析指标项
    path: '/psychology-indicator-item',
    name: 'PsychologyIndicatorItem',
    component: () => import("../views/pages/psychology/PsychologyIndicatorItem.vue"),
},
{ // 心理测评结果记录
    path: '/psychology-record',
    name: 'PsychologyRecord',
    component: () => import("../views/pages/psychology/PsychologyRecord.vue"),
},
{ // 心理测评结果记录详情
    path: '/psychology-record-items',
    name: 'PsychologyRecordItems',
    component: () => import("../views/pages/psychology/PsychologyRecordItems.vue"),
},
{ // 心理测评任务
    path: '/psychology-exam-task',
    name: 'PsychologyExamTask',
    component: () => import("../views/pages/psychology/PsychologyExamTask.vue"),
},
{ // 心理测评任务
    path: '/specialexam-illustrate',
    name: 'SpecialexamIllustrate',
    component: () => import("../views/pages/psychology/SpecialexamIllustrate.vue"),
},
]