/* 将路由数组批量转换成app路由 */
export const converAppRoutes = function (array) {
    const newArray = array.map(x => {
        return {
            ...x,
            name: "App" + x.name,
            path: "/app-" + (x.path.replace('/', '')),
        }
    });
    return newArray;
}