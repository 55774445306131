<!-- 使用id分割的区域组的文字集，v-model传入id组 -->
<template>
    <div>{{ label }}</div>
</template>
<script>
import { reactive, toRefs, onMounted } from "vue";
import globals from "@/utils/globals.js";
export default {
    name: "ZoningGroupLabel",
    props: {
        modelValue: {
            type: String,
            default: ""
        },
    },
    setup(props) {
        const state = reactive({
            label: "",
        });
        onMounted(() => {
            const zoningids = props.modelValue;
            if (zoningids == null) {
                return "";
            }
            const arr = zoningids.split(",");
            const res = arr.map(x => {
                const item = globals.zoning.find(k => k.id == x);
                return item ? item.name : " ";
            });
            state.label = res.join(", ");
        });
        return {
            ...toRefs(state)
        };
    },
};
</script>

<style>

</style>