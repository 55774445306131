/*体测管理（自测） */
export const fiancoSelf = [
    { // 体测数据汇聚
        path: '/fianco-self-report',
        name: 'FiancoSelfReport',
        component: () => import("../views/pages/fiancoSelf/FiancoReport.vue"),
    },
    { // 体测表单项
        path: '/fianco-self-form-items',
        name: 'FiancoSelfFormItems',
        component: () => import("../views/pages/fiancoSelf/FiancoForm-items.vue"),
    },
    { // 体测表单
        path: '/fianco-self-form',
        name: 'FiancoSelfForm',
        component: () => import("../views/pages/fiancoSelf/FiancoForm.vue"),
    },
    { // 体测指标
        path: '/fianco-self-indicator',
        name: 'FiancoSelfIndicator',
        component: () => import("../views/pages/fiancoSelf/FiancoIndicator.vue"),
    },
    { // 体测指标项
        path: '/fianco-self-indicator-item',
        name: 'FiancoSelfIndicatorItem',
        component: () => import("../views/pages/fiancoSelf/FiancoIndicatorItem.vue"),
    },
    { // 体测指标加分项
        path: '/fianco-self-indicator-item-reward',
        name: 'FiancoSelfIndicatorItemreward',
        component: () => import("../views/pages/fiancoSelf/FiancoIndicatorItemreward.vue"),
    },
    { // 体测项目
        path: '/fianco-self-item',
        name: 'FiancoSelfItem',
        component: () => import("../views/pages/fiancoSelf/FiancoItem.vue"),
    },
    { // 体测记录
        path: '/fianco-self-record',
        name: 'FiancoSelfRecord',
        component: () => import("../views/pages/fiancoSelf/FiancoRecord.vue"),
    },
    { // 体测数据汇聚
        path: '/fianco-self-record-converge',
        name: 'FiancoSelfRecordConverge',
        component: () => import("../views/pages/fiancoSelf/FiancoRecordConverge.vue"),
    },
    { // 体测任务
        path: '/fianco-self-task',
        name: 'FiancoSelfTask',
        component: () => import("../views/pages/fiancoSelf/FiancoTask.vue"),
    },
    { // 体测评分占比
        path: '/fianco-self-proportion',
        name: 'FiancoSelfProportion',
        component: () => import("../views/pages/fiancoSelf/FiancoSelfProportion.vue"),
    },
]