/* GIS展示部分 */
export const gis = [{ // GIS监管单位分布
    path: '/gis-regulatory',
    name: 'GISRegulatory',
    component: () => import("../views/pages/GIS/GISRegulatory.vue"),
},
{ // GIS医疗机构分布
    path: '/gis-hospital',
    name: 'GISHospital',
    component: () => import("../views/pages/GIS/GISHospital.vue"),
},
{ // GIS教育机构分布
    path: '/gis-school',
    name: 'GISSchool',
    component: () => import("../views/pages/GIS/GISSchool.vue"),
},
{ // GIS指标异常分布
    path: '/gis-indicator',
    name: 'GISIndicator',
    component: () => import("../views/pages/GIS/GISIndicator.vue"),
},
{ // GIS缺勤学校分布
    path: '/gis-checkin',
    name: 'GISCheckin',
    component: () => import("../views/pages/GIS/GISCheckin.vue"),
},]