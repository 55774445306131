import { createStore } from 'vuex'

export default createStore({
  state: {
    userinfo: {
      username: '',
      nickname: '游客',
      ip: '0.0.0.0',
      lastd: '未登录',
      msgs: 0,
      uid: 0,
      countyid: 0,
    },
    curitem: 'menu-19',
    tabdata: [
      {
        code: 'menu-19',
        name: '首页',
        link: '/home',
        closable: false
      }
    ],
    roles: [],
    WorkbenchIndexTab: 0,
  },
  getters: {
  },
  mutations: {
    setUserInfo(state, data) {
      state.userinfo = data;
    },
    setRoles(state, data) {
      state.roles = data;
    },
  },
  actions: {
  },
  modules: {
  }
})
