/*体测管理 */
export const fianco = [{ // 体测项目
    path: '/fianco-item',
    name: 'FiancoItem',
    component: () => import("../views/pages/fianco/FiancoItem.vue"),
},
{ // 体测表单
    path: '/fianco-form',
    name: 'FiancoForm',
    component: () => import("../views/pages/fianco/FiancoForm.vue"),
},
{ // 体测表单项
    path: '/fianco-form-items',
    name: 'FiancoFormItems',
    component: () => import("../views/pages/fianco/FiancoForm-items.vue"),
},
{ // 体测指标
    path: '/fianco-indicator',
    name: 'FiancoIndicator',
    component: () => import("../views/pages/fianco/FiancoIndicator.vue"),
},
{ // 体测指标项
    path: '/fianco-indicator-item',
    name: 'FiancoIndicatorItem',
    component: () => import("../views/pages/fianco/FiancoIndicatorItem.vue"),
},
{ // 体测记录
    path: '/fianco-record',
    name: 'FiancoRecord',
    component: () => import("../views/pages/fianco/FiancoRecord.vue"),
},
{ // 体测任务
    path: '/fianco-task',
    name: 'FiancoTask',
    component: () => import("../views/pages/fianco/FiancoTask.vue"),
},
{ // 体测指标加分项
    path: '/fianco-indicator-item-reward',
    name: 'FiancoIndicatorItemreward',
    component: () => import("../views/pages/fianco/FiancoIndicatorItemreward.vue"),
},
{ // 体测数据汇聚
    path: '/fianco-record-converge',
    name: 'FiancoRecordConverge',
    component: () => import("../views/pages/fianco/FiancoRecordConverge.vue"),
},
{ // 体测数据汇聚
    path: '/fianco-report',
    name: 'FiancoReport',
    component: () => import("../views/pages/fianco/FiancoReport.vue"),
}
]