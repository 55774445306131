export default {
    height: [//身高
        {
            key:"height",
            type:"矮小",
            level: "下",
            suggest:"您的孩子身高低于同年龄、同性别正常儿童生长曲线P3百分位数，达到矮小症的诊断标准。导致儿童身材矮小的原因很多，建议及时到医院进一步明确原因并早期治疗，防止错过最佳干预时间。"
        },
        {
            key:"height",
            type:"正常（身高≤25th）",
            level: "中下",
            suggest:"您的孩子身高中下，建议定期体检，监测身高体重，关注有无青春期发育，加强运动，保证充足的睡眠和营养，平衡膳食。如对身高有更高需求，建议及时到医院身高促进门诊早期干预。"
        },
        {
            key:"height",
            type:"正常",
            level: "中",
            suggest:"您的孩子身高、体重属于正常范围，建议定期体检，监测身高体重，关注有无青春期发育，加强运动，保证充足的睡眠和营养，平衡膳食。"
        },
        {
            key:"height",
            type:"正常（身高≥25th）",
            level: "中上",
            suggest:"您的孩子身高中上、体重属于正常范围，建议定期体检，监测身高体重，关注有无青春期发育，加强运动，保证充足的睡眠和营养，平衡膳食。"
        },
        {
            key:"height",
            type:"超高",
            level: "上",
            suggest:"您的孩子的身高高于同年龄、同性别正常儿童的平均身高，建议平时均衡营养，规律补充维生素D。"
        },
    ],
    bmi: [//BMI
        {
            key:"bmi",
            type:"消瘦",
            suggest:"您的孩子BMI数值处于消瘦水平，建议平时应加强营养，均衡饮食，纠正不良的进食习惯，可适当增加高热量食物的摄入，必要时到医院进一步明确原因，并早期干预。"
        },
        {
            key:"bmi",
            type:"正常",
            suggest:"您的孩子身高、体重属于正常范围，建议定期体检，监测身高体重，关注有无青春期发育，加强运动，保证充足的睡眠和营养，平衡膳食。"
        },
        {
            key:"bmi",
            type:"正常（身高≥25th）",
            suggest:"您的孩子身高、体重属于正常范围，建议定期体检，监测身高体重，关注有无青春期发育，加强运动，保证充足的睡眠和营养，平衡膳食。"
        },
        {
            key:"bmi",
			subkey:"th",
            type:"正常（身高≤25th）",
            suggest:"您的孩子身高中下，建议定期体检，监测身高体重，关注有无青春期发育，加强运动，保证充足的睡眠和营养，平衡膳食。如对身高有更高需求，建议及时到医院儿童保健科身高促进门诊早期干预。"
        },
        {
            key:"bmi",
            type:"超重",
            suggest:"您的孩子的体重相对他（她）的身高明显超重，建议平时加强运动，控制体重增长过快，控制高热量食物的摄入，预防肥胖，定期体检。"
        },
        {
            key:"bmi",
            type:"超重（身高正常）",
            suggest:"您的超重（身高正常）：您的孩子身高属于正常范围，但体重已超重，建议每半年体检一次，监测身高体重，预防肥胖，关注有无青春期发育，加强运动，保证充足的睡眠和营养，低脂饮食。"
        },
        {
            key:"bmi",
            type:"超重（身高中下）",
            suggest:"您的超重（身高中下）：您的孩子身高中下，但体重超重，建议每半年体检一次，监测身高体重，预防肥胖，关注有无青春期发育，加强运动，保证充足的睡眠和营养，平衡膳食。如对身高有更高需求，建议及时到医院儿童保健科身高促进门诊早期干预。"
        },
        {
            key:"bmi",
            type:"肥胖",
            suggest:"您的孩子BMI数值已超过同年龄、同性别正常儿童肥胖界值点，达到肥胖症的诊断标准。儿童肥胖除了会影响其生长和发育、心理健康，还易引起其它并发症，如性早熟、糖尿病、高血压、高血脂、高胰岛素血症、代谢综合征、心血管疾病、呼吸问题（如哮喘和睡眠障碍）等，建议及时到医院儿童保健科明确肥胖原因及有无并发症，并早期干预。"
        },
        {
            key:"bmi",
            type:"营养不良",
            suggest:"您的孩子存在营养不良，建议及时到医院儿童保健科进一步明确营养不良的原因，并早期干预。"
        },
    ],
    bloodpressure: [//血压
        {
            key:"bloodpressure",
            type:"未见异常",
            suggest:""
        },
        {
            key:"bloodpressure",
            type:"高血压",
            suggest:"您的孩子血压异常增高，建议再次复查血压情况，如仍高，及时到医院儿科进一步完善相关检查。"
        },
        {
            key:"bloodpressure",
            type:"异常",
            suggest:"您的孩子的血压异常，建议定期复查，必要时请到医院就诊，进一步明确病因，早期干预。"
        },
    ],
    vision: [//视力
        {
            key:"vision",
            type:"正常",
            suggest:"您的孩子的视力正常，多户外活动，定期进行视力检查。"
        },
        {
            key:"vision",
            type:"异常",
            suggest:"您的孩子的视力异常，建议到医院就诊，进一步明确病因，早期干预。"
        },
    ],
    trachoma: [//沙眼
        {
            key:"trachoma",
            type:"未见异常",
            suggest:""
        },
        {
            key:"trachoma",
            type:"左眼异常",
            suggest:"您的孩子可疑沙眼，建议及时到医院眼科进一步检查。"
        },
        {
            key:"trachoma",
            type:"右眼异常",
            suggest:"您的孩子可疑沙眼，建议及时到医院眼科进一步检查。"
        },
        {
            key:"trachoma",
            type:"双眼异常",
            suggest:"您的孩子可疑沙眼，建议及时到医院眼科进一步检查。"
        },
    ],
    conjunctivitis: [//结膜炎
        {
            key:"conjunctivitis",
            type:"未见异常",
            suggest:""
        },
        {
            key:"conjunctivitis",
            type:"左眼异常",
            suggest:"您的孩子可疑结膜炎，建议及时到医院眼科进一步检查。"
        },
        {
            key:"conjunctivitis",
            type:"右眼异常",
            suggest:"您的孩子可疑结膜炎，建议及时到医院眼科进一步检查。"
        },
        {
            key:"conjunctivitis",
            type:"双眼异常",
            suggest:"您的孩子可疑结膜炎，建议及时到医院眼科进一步检查。"
        },
    ],
    extraocular: [//外眼
        {
            key:"extraocular",
            type:"未见异常",
            suggest:""
        },
        {
            key:"extraocular",
            type:"左眼眼睑下垂",
            suggest:"您的孩子疑似上睑下垂，建议到医院就诊，进一步明确诊断，早期干预。"
        },
        {
            key:"extraocular",
            type:"右眼眼睑下垂",
            suggest:"您的孩子疑似上睑下垂，建议到医院就诊，进一步明确诊断，早期干预。"
        },
        {
            key:"extraocular",
            type:"双眼眼睑下垂",
            suggest:"您的孩子疑似上睑下垂，建议到医院就诊，进一步明确诊断，早期干预。"
        },
        {
            key:"extraocular",
            type:"左眼斜视",
            suggest:"您的孩子疑似斜视，建议到医院就诊，进一步明确诊断，早期干预。"
        },
        {
            key:"extraocular",
            type:"右眼斜视",
            suggest:"您的孩子疑似斜视，建议到医院就诊，进一步明确诊断，早期干预。"
        },
        {
            key:"extraocular",
            type:"双眼斜视",
            suggest:"您的孩子疑似斜视，建议到医院就诊，进一步明确诊断，早期干预。"
        },
        {
            key:"extraocular",
            type:"其他",
            suggest:"您的孩子疑似异常，建议到医院就诊，进一步明确诊断，早期干预。"
        },
    ],
    colorperception: [//辩色
        {
            key:"colorperception",
            type:"未见异常",
            suggest:""
        },
        {
            key:"colorperception",
            type:"色弱",
            suggest:"您的孩子辩色能力疑似异常，建议到医院就诊，进一步明确诊断，早期干预。"
        },
        {
            key:"colorperception",
            type:"色盲",
            suggest:"您的孩子辩色能力疑似异常，建议到医院就诊，进一步明确诊断，早期干预。"
        },
        {
            key:"colorperception",
            type:"其他",
            suggest:"您的孩子辩色能力疑似异常，建议到医院就诊，进一步明确诊断，早期干预。"
        },
    ],
    dioptric: [//屈光
        {
            key:"dioptric",
            type:"未见异常",
            suggest:""
        },
        {
            key:"dioptric",
            type:"异常",
            suggest:"您的孩子的屈光疑似异常，建议到医院就诊，进一步明确病因，早期干预。"
        },
    ],
    hearing: [//听力
        {
            key:"hearing",
            type:"未见异常",
            suggest:""
        },
        {
            key:"hearing",
            type:"左耳异常",
            suggest:"您的孩子的听力疑似异常，建议到医院就诊，进一步明确病因，早期干预。"
        },
        {
            key:"hearing",
            type:"右耳异常",
            suggest:"您的孩子的听力疑似异常，建议到医院就诊，进一步明确病因，早期干预。"
        },
        {
            key:"hearing",
            type:"双耳异常",
            suggest:"您的孩子的听力疑似异常，建议到医院就诊，进一步明确病因，早期干预。"
        },
    ],
    ear: [//外耳
        {
            key:"ear",
            type:"未见异常",
            suggest:""
        },
        {
            key:"ear",
            type:"左耳畸形",
            suggest:"您的孩子的外耳疑似异常，建议到医院就诊，进一步明确病因，早期干预。"
        },
        {
            key:"ear",
            type:"右耳畸形",
            suggest:"您的孩子的外耳疑似异常，建议到医院就诊，进一步明确病因，早期干预。"
        },
        {
            key:"ear",
            type:"双耳畸形",
            suggest:"您的孩子的外耳疑似异常，建议到医院就诊，进一步明确病因，早期干预。"
        },
        {
            key:"ear",
            type:"左耳外耳道异常分泌物",
            suggest:"您的孩子的外耳疑似异常，建议到医院就诊，进一步明确病因，早期干预。"
        },
        {
            key:"ear",
            type:"右耳外耳道异常分泌物",
            suggest:"您的孩子的外耳疑似异常，建议到医院就诊，进一步明确病因，早期干预。"
        },
        {
            key:"ear",
            type:"双耳外耳道异常分泌物",
            suggest:"您的孩子的外耳疑似异常，建议到医院就诊，进一步明确病因，早期干预。"
        },
        {
            key:"ear",
            type:"其他",
            suggest:"您的孩子的外耳疑似异常，建议到医院就诊，进一步明确病因，早期干预。"
        },
    ],
    nose: [//鼻
        {
            key:"nose",
            type:"未见异常",
            suggest:""
        },
        {
            key:"nose",
            type:"脓性分泌物",
            suggest:"您的孩子的鼻腔疑似有异常分泌物，建议到医院就诊，进一步明确病因，早期干预。"
        },
        {
            key:"nose",
            type:"鼻出血",
            suggest:"您的孩子的鼻腔疑似鼻出血，建议到医院就诊，进一步明确病因，早期干预。"
        },
        {
            key:"nose",
            type:"鼻息肉",
            suggest:"您的孩子的鼻腔疑似有鼻息肉，建议到医院就诊，进一步明确病因，早期干预。"
        },
        {
            key:"nose",
            type:"其他",
            suggest:"您的孩子的鼻腔疑似有异常，建议到医院就诊，进一步明确病因，早期干预。"
        },
    ],
    tonsil: [//扁桃体
        {
            key:"tonsil",
            type:"未见异常",
            suggest:""
        },
        {
            key:"tonsil",
            type:"扁桃体肿大",
            suggest:"您的孩子扁桃体2度肿大，如无其他不适症状，可无需处理，清淡饮食，避免辛辣刺激食物，增强体质，定期体检即可。如有反复红肿、发热，或双侧不对称肿大等，建议及时到医院耳鼻喉科进一步检查和治疗。"
        },
        {
            key:"tonsil",
            type:"Ⅰ度肿大",
            suggest:"您的孩子扁桃体疑似肿大，建议清淡饮食，保持充足睡眠，规律作息，如发现双侧逐渐肿大或者不对称，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
        {
            key:"tonsil",
            type:"Ⅱ度肿大",
            suggest:"您的孩子扁桃体疑似Ⅱ度肿大，如无其他不适症状，可无需处理，清淡饮食，避免辛辣刺激食物，增强体质，定期体检即可。如有反复红肿、发热，或双侧不对称肿大等，建议及时到医院耳鼻喉科进一步检查和治疗。"
        },
        {
            key:"tonsil",
            type:"Ⅲ度肿大",
            suggest:"您的孩子扁桃体疑似Ⅲ度肿大，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
    ],
    teeth: [//牙齿
        {
            key:"teeth",
            type:"未见异常",
            suggest:""
        },
        {
            key:"teeth",
            type:"龋牙",
            suggest:"您的孩子患有龋齿，建议到医院就诊，早期干预预防加重。"
        },
    ],
    periodontal: [//牙周
        {
            key:"periodontal",
            type:"未见异常",
            suggest:""
        },
        {
            key:"periodontal",
            type:"牙龈炎",
            suggest:"您的孩子的牙周疑似存在异常情况，建议到医院就诊，进一步明确病因，早期干预。"
        },
        {
            key:"periodontal",
            type:"牙周炎",
            suggest:"您的孩子的牙周疑似存在异常情况，建议到医院就诊，进一步明确病因，早期干预。"
        },
        {
            key:"periodontal",
            type:"牙菌斑",
            suggest:"您的孩子的牙周疑似存在异常情况，建议到医院就诊，进一步明确病因，早期干预。"
        },
        {
            key:"periodontal",
            type:"牙列不齐",
            suggest:"您的孩子的牙周疑似存在异常情况，建议到医院就诊，进一步明确病因，早期干预。"
        },
        {
            key:"periodontal",
            type:"其他",
            suggest:"您的孩子的牙周疑似存在异常情况，建议到医院就诊，进一步明确病因，早期干预。"
        },
    ],
    heart: [//心
        {
            key:"heart",
            type:"未见异常",
            suggest:""
        },
        {
            key:"heart",
            type:"心脏杂音",
            suggest:"您的孩子心脏听诊疑似异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
        {
            key:"heart",
            type:"其他",
            suggest:"您的孩子心脏听诊疑似异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
    ],
    lung: [//肺
        {
            key:"lung",
            type:"未见异常",
            suggest:""
        },
        {
            key:"lung",
            type:"呼吸声粗",
            suggest:"您的孩子肺部听诊疑似异常，请关注孩子的身体疾病情况，若症状加重或有变化，建议及时到医院进一步明确病因，干预治疗。"
        },
        {
            key:"lung",
            type:"湿罗音",
            suggest:"您的孩子肺部听诊疑似异常，请关注孩子的身体疾病情况，若症状加重或有变化，建议及时到医院进一步明确病因，干预治疗。"
        },
        {
            key:"lung",
            type:"干啰音",
            suggest:"您的孩子肺部听诊疑似异常，请关注孩子的身体疾病情况，若症状加重或有变化，建议及时到医院进一步明确病因，干预治疗。"
        },
        {
            key:"lung",
            type:"哮喘音",
            suggest:"您的孩子肺部听诊疑似异常，请关注孩子的身体疾病情况，若症状加重或有变化，建议及时到医院进一步明确病因，干预治疗。"
        },
        {
            key:"lung",
            type:"其他",
            suggest:"您的孩子肺部听诊疑似异常，请关注孩子的身体疾病情况，若症状加重或有变化，建议及时到医院进一步明确病因，干预治疗。"
        },
    ],
    liver: [//肝
        {
            key:"liver",
            type:"未见异常",
            suggest:""
        },
        {
            key:"liver",
            type:"肝脏肿大",
            suggest:"您的孩子肝脏触诊疑似异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
        {
            key:"liver",
            type:"其他",
            suggest:"您的孩子肝脏触诊疑似异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
    ],
    spleen: [//脾
        {
            key:"spleen",
            type:"未见异常",
            suggest:""
        },
        {
            key:"spleen",
            type:"肋下触及",
            suggest:"您的孩子脾脏触诊疑似异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
        {
            key:"spleen",
            type:"其他",
            suggest:"您的孩子脾脏触诊疑似异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
    ],
    head: [//头部
        {
            key:"head",
            type:"未见异常",
            suggest:""
        },
        {
            key:"head",
            type:"其他",
            suggest:"您的孩子头部触诊疑似异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
    ],
    neck: [//颈部
        {
            key:"neck",
            type:"未见异常",
            suggest:""
        },
        {
            key:"neck",
            type:"其他",
            suggest:"您的孩子颈部触诊疑似异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
    ],
    chest: [//胸部
        {
            key:"chest",
            type:"未见异常",
            suggest:""
        },
        {
            key:"chest",
            type:"鸡胸",
            suggest:"您的孩子存在鸡胸体征，建议及时到医院儿童保健科进一步检查，了解鸡胸原因。"
        },
        {
            key:"chest",
            type:"漏斗胸",
            suggest:"您的孩子存在漏斗胸体征，建议规律补充维生素D，预防维生素D缺乏引起的相关疾病，定期体检复查，必要时可到医院进一步明确病因。尽早干预治疗。"
        },
        {
            key:"chest",
            type:"胸廓不对称",
            suggest:"您的孩子存在胸廓不对称体征，建议平时注意纠正不良姿势，定期体检复查，必要时可到医院进一步明确病因，尽早干预治疗。"
        },
        {
            key:"chest",
            type:"肋外翻",
            suggest:"您的孩子存在肋外翻体征，建议平时注意纠正不良姿势，定期体检复查，必要时可到医院进一步明确病因，尽早干预治疗。"
        },
        {
            key:"chest",
            type:"其他",
            suggest:"您的孩子胸部疑似异常，建议到医院就诊，进一步明确病因，早期干预。"
        },
    ],
    spine: [//脊柱
        {
            key:"spine",
            type:"未见异常",
            suggest:""
        },
        {
            key:"spine",
            type:"脊柱侧弯",
            suggest:"您的孩子脊柱检测异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
        {
            key:"spine",
            type:"高低肩",
            suggest:"您的孩子双肩不等高，疑似脊柱侧弯，建议及时到医院儿童保健科进一步检查。"
        },
        {
            key:"spine",
            type:"翼状肩",
            suggest:"您的孩子脊柱检测异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
        {
            key:"spine",
            type:"其他",
            suggest:"您的孩子脊柱检测异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
    ],
    limbs: [//四肢
        {
            key:"limbs",
            type:"未见异常",
            suggest:""
        },
        {
            key:"limbs",
            type:"O型腿",
            suggest:"您的孩子腿型为O型腿，建议及时到医院康复医学科进一步检查，以免造成体态不良。"
        },
        {
            key:"limbs",
            type:"X型腿",
            suggest:"您的孩子腿型为X型腿，建议及时到医院康复医学科进一步检查，以免造成体态不良。"
        },
        {
            key:"limbs",
            type:"其他",
            suggest:"您的孩子四肢触诊异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
    ],
    skin: [//皮肤
        {
            key:"skin",
            type:"未见异常",
            suggest:""
        },
        {
            key:"skin",
            type:"湿疹",
            suggest:"您的孩子皮肤疑似湿疹，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
        {
            key:"skin",
            type:"血管瘤",
            suggest:"您的孩子皮肤疑似血管瘤，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
        {
            key:"skin",
            type:"牛奶咖啡斑",
            suggest:"您的孩子皮肤疑似牛奶咖啡斑，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
        {
            key:"skin",
            type:"黑棘皮征",
            suggest:"您的孩子皮肤疑似黑棘皮征（+），建议及时到医院儿童保健科进一步就诊。"
        },
        {
            key:"skin",
            type:"黑棘皮征（+）",
            suggest:"您的孩子皮肤疑似黑棘皮征（+），建议及时到医院儿童保健科进一步就诊。"
        },
        {
            key:"skin",
            type:"其他",
            suggest:"您的孩子皮肤疑似异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
    ],
	genitalia: [//外生殖器
	    {
	        key:"genitalia",
	        type:"可疑性早熟",
	        suggest:"您的孩子可疑存在性早熟或青春期发育过早，发育提前会影响孩子的成年身高及心理发育，建议及时到医院儿童保健科明确诊断，了解有无青春期发育过早，防止错过最佳干预时间。"
	    },
	    {
	        key:"genitalia",
	        type:"包茎",
	        suggest:"包茎，建议及时到医院小儿外科进一步检查。"
	    },
	    {
	        key:"genitalia",
	        type:"小阴茎",
	        suggest:"小阴茎，建议及时到医院儿童保健科进一步检查。"
	    },
	    {
	        key:"genitalia",
	        type:"隐匿性阴茎",
	        suggest:"您的孩子外生殖器疑似隐匿性阴茎，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
	    },
        {
            key:"genitalia",
            type:"其他",
            suggest:"您的孩子外生殖器疑似异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
	],
    lymphnode: [//淋巴结
        {
            key:"lymphnode",
            type:"未见异常",
            suggest:""
        },
        {
            key:"lymphnode",
            type:"耳前淋巴结肿大",
            suggest:"您的孩子淋巴结触诊异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
        {
            key:"lymphnode",
            type:"耳后淋巴结肿大",
            suggest:"您的孩子淋巴结触诊异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
        {
            key:"lymphnode",
            type:"下颌部淋巴结肿大",
            suggest:"您的孩子淋巴结触诊异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
        {
            key:"lymphnode",
            type:"颈部淋巴结肿大",
            suggest:"您的孩子淋巴结触诊异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
        {
            key:"lymphnode",
            type:"锁骨上窝淋巴结肿大",
            suggest:"您的孩子淋巴结触诊异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
        {
            key:"lymphnode",
            type:"腹部淋巴结肿大",
            suggest:"您的孩子淋巴结触诊异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
        {
            key:"lymphnode",
            type:"脐周淋巴结肿大",
            suggest:"您的孩子淋巴结触诊异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
        {
            key:"lymphnode",
            type:"腋窝淋巴结肿大",
            suggest:"您的孩子淋巴结触诊异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
        {
            key:"lymphnode",
            type:"肘关节淋巴结肿大",
            suggest:"您的孩子淋巴结触诊异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
        {
            key:"lymphnode",
            type:"腹股沟区淋巴结肿大",
            suggest:"您的孩子淋巴结触诊异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
        {
            key:"lymphnode",
            type:"腘窝淋巴结肿大",
            suggest:"您的孩子淋巴结触诊异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
        {
            key:"lymphnode",
            type:"其他",
            suggest:"您的孩子淋巴结疑似异常，建议及时到医院进一步就诊明确病因，尽早干预治疗。"
        },
    ],
    hb: [//血红蛋白
        {
            key:"hb",
            type:"未见异常",
            suggest:""
        },
        {
            key:"hb",
            type:"轻度贫血",
            suggest:"您的孩子存在轻度贫血，建议到医院进一步明确贫血原因，并早期干预。"
        },
        {
            key:"hb",
            type:"中度贫血",
            suggest:"您的孩子存在中度贫血，建议及时到医院进一步明确贫血原因，并早期干预。"
        },
        {
            key:"hb",
            type:"重度贫血",
            suggest:"您的孩子存在重度贫血，建议及时到医院进一步明确贫血原因，并早期干预。"
        },
    ],
    alt: [//丙氨酸氨基转移酶
        {
            key:"alt",
            type:"正常",
            suggest:""
        },
        {
            key:"alt",
            type:"异常",
            suggest:"您的孩子转氨酶升高，建议及时到医院进一步查找原因并治疗"
        },
    ],
    tbil: [//胆红素
        {
            key:"tbil",
            type:"正常",
            suggest:""
        },
        {
            key:"tbil",
            type:"异常",
            suggest:"您的孩子胆红素升高，建议及时到医院进一步查找原因并治疗"
        },
    ],
    whitecells: [//白细胞
        {
            key:"whitecells",
            type:"正常",
            suggest:""
        },
        {
            key:"whitecells",
            type:"异常",
            suggest:"您的孩子白细胞异常，建议及时到医院进一步查找原因并治疗"
        },
    ],
    redcells: [//红细胞
        {
            key:"redcells",
            type:"正常",
            suggest:""
        },
        {
            key:"redcells",
            type:"异常",
            suggest:"您的孩子红细胞异常，建议及时到医院进一步查找原因并治疗"
        },
    ],
    plt: [//血小板
        {
            key:"plt",
            type:"正常",
            suggest:""
        },
        {
            key:"plt",
            type:"异常",
            suggest:"您的孩子血小板异常，建议及时到医院进一步查找原因并治疗"
        },
    ],
}