/* 用户基础功能部分 */
export const userbase = [
	{ // 驾驶舱
	    path: '/home',
	    name: 'Home',
	    component: () => import("../views/DataCockpit.vue"),
	},
// 	{ // 首页
//     path: '/home',
//     name: 'Home',
//     component: () => import("../views/Home.vue"),
// },
    { // 用户信息
        path: '/account',
        name: 'Account',
        component: () => import("../views/Account.vue"),
    },
    { // 修改密码
        path: '/passedit',
        name: 'Passedit',
        component: () => import("../views/Passedit.vue"),
    },
    { // 通知消息
        path: '/message',
        name: 'CommunalMessage',
        component: () => import("../views/pages/communal/CommunalMessage.vue"),
    },
    { // 通知消息
        path: '/user-logs',
        name: 'UserLogs',
        component: () => import("../views/pages/communal/UserLogs.vue"),
    }
]