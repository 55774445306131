/* 基础设置模块 */
export const baseSetting = [{ // 管理机构管理
    path: '/department',
    name: 'Department',
    component: () => import("../views/pages/system/Department.vue"),
},
{ // 下级机构管理
    path: '/regulatory-department',
    name: 'RegulatoryDepartment',
    component: () => import("../views/pages/regulatory/RegulatoryDepartment.vue"),
},
{ // 管理机构部门管理
    path: '/regulatory-branch',
    name: 'Branch',
    component: () => import("../views/pages/system/Branch.vue"),
},
{ // 管理机构单位信息
    path: '/regulatory-department-form',
    name: 'RegulatoryDepartmentForm',
    component: () => import("../views/pages/regulatory/RegulatoryDepartmentForm.vue"),
},
{ // 医疗机构管理
    path: '/hospital-department',
    name: 'HospitalDepartment',
    component: () => import("../views/pages/hospital/HospitalDepartment.vue"),
},
{ // 医疗机构部门管理
    path: '/hospital-branch',
    name: 'HospitalBranch',
    component: () => import("../views/pages/hospital/HospitalBranch.vue"),
},
{ // 医疗机构单位信息管理
    path: '/hospital-department-form',
    name: 'HospitalDepartmentForm',
    component: () => import("../views/pages/hospital/HospitalDepartmentForm.vue"),
},
{ // 教育机构管理
    path: '/school-department',
    name: 'SchoolDepartment',
    component: () => import("../views/pages/school/SchoolDepartment.vue"),
},
{ // 班级部门管理
    path: '/school-branch',
    name: 'SchoolBranch',
    component: () => import("../views/pages/school/SchoolBranch.vue"),
},
{ // 教育机构信息
    path: '/school-department-form',
    name: 'SchoolDepartmentForm',
    component: () => import("../views/pages/school/SchoolDepartmentForm.vue"),
},
{ // 内部角色管理
    path: '/branch-role',
    name: 'BranchRole',
    component: () => import("../views/pages/system/BranchRole.vue"),
}]