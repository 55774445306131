<!-- 区域多选组 -->
<template>
    <div>
        <el-checkbox label="全市" v-model="checkAll" :indeterminate="isIndeterminate" @change="changeCheckAll" />
        <el-checkbox-group v-model="selects">
            <el-checkbox v-for="item in zoning" :label="item.id" :key="item.id" @change="changeCheck">{{ item.name }}
            </el-checkbox>
        </el-checkbox-group>
    </div>
</template>
<script>
import { reactive, toRefs, onMounted, onUpdated } from "vue";
import globals from "@/utils/globals.js";
export default {
    name: "ZoningCheckboxGroup",
    props: {
        modelValue: {
            type: String,
            default: ""
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            checkAll: false,
            isIndeterminate: false,
            zoning: [],
            selects: [],
            selectIds: '',
        });
        const changeCheck = () => {
            state.checkAll = state.selects.length > 0;
            state.isIndeterminate = state.selects.length > 0 && state.selects.length != state.zoning.length;
            emit('update:modelValue', state.selects.join(','));
        };
        const changeCheckAll = (val) => {
            state.selects.splice(0);
            if (val) {
                state.zoning.forEach(x => {
                    state.selects.push(x.id);
                });
            }
            state.checkAll = state.isIndeterminate = val;
            emit('update:modelValue', state.selects.join(','));
        };
        onMounted(() => {
            if (props.modelValue != null && props.modelValue != '') {
                state.selects = props.modelValue.split(',');
                state.selectIds = state.selects.join(',');
            }
            const userInfo = JSON.parse(globals.local.userInfo);
            state.zoning = globals.filterZoings(userInfo.countyid);
            changeCheck();
        })
        onUpdated(() => {
            if (state.selectIds != props.modelValue) {
                if (props.modelValue != null && props.modelValue != '') {
                    state.selects = props.modelValue.split(',');
                    state.selectIds = state.selects.join(',');
                } else {
                    state.selects = [];
                    state.selectIds = '';
                }
                changeCheck();
            }
        });
        return {
            ...toRefs(state),
            changeCheck,
            changeCheckAll
        };
    },
};
</script>

<style></style>