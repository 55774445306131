/* 用户管理模块 */
export const userMgr = [
    { // 管理机构员工档案
        path: '/regulatory-employee-archive',
        name: 'RegulatoryEmployeeArchive',
        component: () => import("../views/pages/regulatory/RegulatoryEmployeeArchive.vue"),
    },
    { // 医疗机构员工档案
        path: '/hospital-employee-archive',
        name: 'HospitalEmployeeArchive',
        component: () => import("../views/pages/hospital/HospitalEmployeeArchive.vue"),
    },
    { // 医疗机构管理账号
        path: '/hospital-user',
        name: 'HospitalUser',
        component: () => import("../views/pages/hospital/HospitalUser.vue"),
    },
    { // 教职工档案
        path: '/school-employee-archive',
        name: 'SchoolEmployeeArchive',
        component: () => import("../views/pages/school/SchoolEmployeeArchive.vue"),
    },
    { // 学校管理账号
        path: '/school-user',
        name: 'SchoolUser',
        component: () => import("../views/pages/school/SchoolUser.vue"),
    },
    { // 普通人员档案
        path: '/communal-personal-archive',
        name: 'CommunalPersonalArchiveVue',
        component: () => import("../views/pages/communal/CommunalPersonalArchive.vue"),
    }]