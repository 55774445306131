/** 静态数据写在这里，然后从globals导出 */
export default {
    zoning: [{
        id: '27058',
        name: '柳北区'
    }, {
        id: '27050',
        name: '城中区'
    }, {
        id: '27084',
        name: '鱼峰区'
    }, {
        id: '27097',
        name: '柳南区'
    }, {
        id: '27109',
        name: '柳江区'
    }, {
        id: '27071',
        name: '柳城县'
    }, {
        id: '27040',
        name: '鹿寨县'
    }, {
        id: '27027',
        name: '融安县'
    }, {
        id: '27006',
        name: '融水苗族自治县'
    }, {
        id: '26990',
        name: '三江侗族自治县'
    }],
    studentTypes: [{
        id: 1,
        name: "学龄前儿童",
        schoolType: "幼儿园",
    }, {
        id: 2,
        name: "小学生",
        schoolType: "小学",
    }, {
        id: 3,
        name: "初中/高中",
        schoolType: "初中/高中",
    }, {
        id: 4,
        name: "中专",
        schoolType: "中专",
    }, {
        id: 5,
        name: "本科/专科",
        schoolType: "本科/专科",
    }],
    pageSizes: [5, 10, 15, 20, 30, 40, 50, 100, 200, 500, 1000],
    zoningIds: "27058,27050,27084,27097,27109,27071,27040,27027,27006,26990",
	color: "white", //x或y轴或y轴上方单位的字体的颜色
}