<template>
    <div style="width: 100%;">
        <el-select v-model="value" placeholder="请选择测试类型" @change="onChange">
            <el-option v-for="item in options" :key="item[0]" :label="item[1]" :value="item[0]" />
        </el-select>
    </div>
</template>
<script>
import { reactive, toRefs, onUpdated } from "vue";
import globals from "@/utils/globals.js";
export default {
    name: "PsychologyQuestionsTypeSelect",
    props: {
        modelValue: {
            type: Number,
            default: 0,
        },
    },
    setup(props, { emit }) {
        const state = reactive({
            value: 0,
            options: [],
        });
        const loadRecords = () => {
            globals.get('psychology-questions-type/listData', {}, (data) => {
                state.options = data.info;
                updateSelcted();
            });
        };
        const updateSelcted = () => {
            if (props.modelValue != 0) {
                state.value = props.modelValue;
            } else {
                if (state.options.length > 0) {
                    state.value = state.options[0][0];
                }
            }
        };
        const onChange = () => {
            emit('update:modelValue', state.value);
            emit('change', state.options.find(x => x[0] == state.value));
        };
        loadRecords();
        onUpdated(() => {
            if (state.value != props.modelValue) {
                updateSelcted();
            }
        });
        return {
            ...toRefs(state),
            onChange
        };
    },
};
</script>

<style>

</style>