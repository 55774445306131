import service from '../utils/service.js'
// 返回系统模块请求api接口列表
export default {
  /* 授权相关接口 */
  /* 
      用户登录
      @param form 请求参数：u 用户名、p 密码
      @param callback 请求回调函数
  */
  signin(form, callback) {
    service.doRequest('POST', '/signin', form, callback);
  },
  /* 
      用户注销
      @param callback 请求回调函数
  */
  signout(callback) {
    service.doRequest('DELETE', '/signout', {}, callback);
  },

  /* 初始化相关接口 */
  /* 
      获取用户信息
      @param callback 请求回调函数
  */
  getUserInfo(callback) {
    service.doRequest('GET', '/account/info', {}, callback);
  },
  /* 
      获取菜单列表
      @param callback 请求回调函数
  */
  getMenus(rid, callback) {
    service.doRequest('GET', '/system-menu/lists', { rid }, callback);
  },

  /* 账号管理相关接口 */
  /* 
      保存用户信息
      @param form 请求参数：n 用户昵称
      @param callback 请求回调函数
  */
  saveAccount(form, callback) {
    service.doRequest('POST', '/account/save', form, callback);
  },
  /* 
      保存修改密码
      @param form 请求参数：p0 原密码、p1 新密码
      @param callback 请求回调函数
  */
  savePassword(id,form, callback) {
    service.doRequest('POST', '/system-user/uppass/'+id, form, callback);
  }
}