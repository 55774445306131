/* 功能管理模块 */
export const functionMgr = [{ // 分析数据查询
    path: '/communal-model-analyses',
    name: 'CommunalModelAnalysesVue',
    component: () => import("../views/pages/communal/CommunalModelAnalyses.vue"),
},
{ // 分析模型设置
    path: '/basedata-model',
    name: 'BasedataModelVue',
    component: () => import("../views/pages/basedata/BasedataModel.vue"),
},
{ //管辖学校管理
    path: '/regulatory-supervise',
    name: 'RegulatorySupervise',
    component: () => import("../views/pages/regulatory/RegulatorySupervise.vue"),
},
{ // 体检指标管理
    path: '/basedata-indicator',
    name: 'BasedataIndicator',
    component: () => import("../views/pages/basedata/BasedataIndicator.vue"),
},
{ // 学生体检关键字管理
    path: '/basedata-medicaltest-keyword',
    name: 'BasedataMedicaltestKeyword',
    component: () => import("../views/pages/basedata/BasedataMedicaltestKeyword.vue"),
},
{ // 角色模板
    path: '/basedata-role',
    name: 'basedataRole',
    component: () => import("../views/pages/basedata/basedataRole.vue"),
},
{ // 检查指标
    path: '/medicaltest-indicator',
    name: 'medicaltestIndicator',
    component: () => import("../views/pages/basedata/MedicaltestIndicator.vue"),
},
{ // 民族代码
    path: '/basedata-nation',
    name: 'BasedataNation',
    component: () => import("../views/pages/basedata/BasedataNation.vue"),
}
]