/* GIS展示部分 */
export const systemMgr = [{ // 菜单管理
    path: '/system-menu',
    name: 'SystemMenu',
    component: () => import("../views/pages/system/SystemMenu.vue"),
},
{ // 角色权限管理
    path: '/system-role',
    name: 'SystemRole',
    component: () => import("../views/pages/system/SystemRole.vue"),
},
{ // 账号管理
    path: '/system-user',
    name: 'SystemUser',
    component: () => import("../views/pages/system/SystemUser.vue"),
},
{ // 参数配置
    path: '/system-variable',
    name: 'SystemVariable',
    component: () => import("../views/pages/system/SystemVariable.vue"),
},
{ // 操作日志
    path: '/system-logs',
    name: 'SystemLogs',
    component: () => import("../views/pages/system/SystemLogs.vue"),
}]