export const studentMedicaltestEye = [
    {
        // 0-6岁儿童体检数据查看
        path: '/student-medicaltest-eye',
        name: 'StudentMedicaltestEye',
        component: () => import('../views/pages/eye/StudentMedicaltestEye.vue')
    },
    {
        // 0-6岁儿童数据信息
        path: '/children-archive',
        name: 'ChildrenArchive',
        component: () => import('../views/pages/eye/ChildrenArchive.vue')
    },
];