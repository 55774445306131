/* 数据管理模块 */
export const studentMedicaltest = [
  {
    // 学生体检数据查看列表
    path: '/student-medicaltest-lists',
    name: 'StudentMedicaltestLists',
    component: () =>
      import('../views/pages/student/StudentMedicaltestLists.vue')
  },
  {
    // 学生体检指标异常
    path: '/student-medicaltest-indicator',
    name: 'StudentMedicaltestIndicator',
    component: () =>
      import('../views/pages/student/StudentMedicaltestIndicator.vue')
  },
  {
    // 学生体检指标异常(汇总)
    path: '/student-medicaltest-indicator-total',
    name: 'StudentMedicaltestIndicatorTotal',
    component: () =>
      import('../views/pages/student/StudentMedicaltestIndicatorTotal.vue')
  },
  {
    // 学生体检上传记录
    path: '/student-medicaltest-import',
    name: 'StudentMedicaltestImport',
    component: () =>
      import('../views/pages/student/StudentMedicaltestImport.vue')
  },
  {
    // 学生体检导入记录预览
    path: '/student-medicaltest-preview',
    name: 'StudentMedicaltestPreview',
    component: () =>
      import('../views/pages/student/StudentMedicaltestPreview.vue')
  },
  {
    // 学生体检记录审核
    path: '/student-medicaltest-check',
    name: 'StudentMedicaltestCheck',
    component: () =>
      import('../views/pages/student/StudentMedicaltestCheck.vue')
  },

  {
    // 整合体检文件解析
    path: '/student-medicaltest-temp',
    name: 'StudentMedicaltestTemp',
    component: () =>
      import('../views/pages/student/temp/StudentMedicaltestTemp.vue')
  },
  {
    // 幼儿园体检文件解析
    path: '/student-medicaltest-temp1',
    name: 'StudentMedicaltestTemp1',
    component: () =>
      import('../views/pages/student/temp/StudentMedicaltestTemp1.vue')
  },
  {
    // 小学生体检文件解析
    path: '/student-medicaltest-temp2',
    name: 'StudentMedicaltestTemp2',
    component: () =>
      import('../views/pages/student/temp/StudentMedicaltestTemp2.vue')
  },
  {
    // 中学生体检文件解析
    path: '/student-medicaltest-temp3',
    name: 'StudentMedicaltestTemp3',
    component: () =>
      import('../views/pages/student/temp/StudentMedicaltestTemp3.vue')
  },
  {
    // 中专生体检文件解析
    path: '/student-medicaltest-temp4',
    name: 'StudentMedicaltestTemp4',
    component: () =>
      import('../views/pages/student/temp/StudentMedicaltestTemp4.vue')
  },
  {
    // 大专/大学生体检文件解析
    path: '/student-medicaltest-temp5',
    name: 'StudentMedicaltestTemp5',
    component: () =>
      import('../views/pages/student/temp/StudentMedicaltestTemp5.vue')
  },
  {
    // 学生体检数据编辑列表
    path: '/student-medicaltest-list',
    name: 'StudentMedicaltestList',
    component: () => import('../views/pages/student/StudentMedicaltestList.vue')
  },
  {
    // 学生体检数据(整合表单)
    path: '/student-medicaltest-form',
    name: 'StudentMedicaltestForm',
    component: () => import('../views/pages/student/StudentMedicaltestForm.vue')
  },
  {
    path: '/student-medicaltest-form1',
    name: 'StudentMedicaltestForm1',
    component: () =>
      import('../views/pages/medicaltests/StudentMedicaltestForm1.vue')
  },
  {
    path: '/student-medicaltest-form2',
    name: 'StudentMedicaltestForm2',
    component: () =>
      import('../views/pages/medicaltests/StudentMedicaltestForm2.vue')
  },
  {
    path: '/student-medicaltest-form3',
    name: 'StudentMedicaltestForm3',
    component: () =>
      import('../views/pages/medicaltests/StudentMedicaltestForm3.vue')
  },
  {
    path: '/student-medicaltest-form4',
    name: 'StudentMedicaltestForm4',
    component: () =>
      import('../views/pages/medicaltests/StudentMedicaltestForm4.vue')
  },
  {
    path: '/student-medicaltest-form5',
    name: 'StudentMedicaltestForm5',
    component: () =>
      import('../views/pages/medicaltests/StudentMedicaltestForm5.vue')
  },
  {
    // 学生体检数据(整合详情)
    path: '/student-medicaltest-info',
    name: 'StudentMedicaltestInfo',
    component: () => import('../views/pages/student/StudentMedicaltestInfo.vue')
  },
  {
    path: '/student-medicaltest-info1',
    name: 'StudentMedicaltestInfo1',
    component: () =>
      import('../views/pages/medicaltests/StudentMedicaltestInfo1.vue')
  },
  {
    path: '/student-medicaltest-info2',
    name: 'StudentMedicaltestInfo2',
    component: () =>
      import('../views/pages/medicaltests/StudentMedicaltestInfo2.vue')
  },
  {
    path: '/student-medicaltest-info3',
    name: 'StudentMedicaltestInfo3',
    component: () =>
      import('../views/pages/medicaltests/StudentMedicaltestInfo3.vue')
  },
  {
    path: '/student-medicaltest-info4',
    name: 'StudentMedicaltestInfo4',
    component: () =>
      import('../views/pages/medicaltests/StudentMedicaltestInfo4.vue')
  },
  {
    path: '/student-medicaltest-info5',
    name: 'StudentMedicaltestInfo5',
    component: () =>
      import('../views/pages/medicaltests/StudentMedicaltestInfo5.vue')
  },
  {
    // 学生体检数据预览列表
    path: '/student-medicaltest-preview-list',
    name: 'StudentMedicaltestpreviewList',
    component: () =>
      import('../views/pages/student/StudentMedicaltestpreviewList.vue')
  },
  {
    // 学生体检任务
    path: '/student-medicaltest-task',
    name: 'StudentMedicaltestTaskVue',
    component: () => import('../views/pages/student/StudentMedicaltestTask.vue')
  },
  {
    // 学生档案管理
    path: '/student-archive',
    name: 'StudentArchive',
    component: () => import('../views/pages/student/StudentArchive.vue')
  },
  {
    // 体检二维码打印
    path: '/student-archive-info',
    name: 'StudentArchiveInfo',
    component: () => import('../views/pages/student/StudentArchiveInfo.vue')
  },
  /* 学生管理部分(废弃) */
  {
    // 学生体检数据 - 化验信息
    path: '/student-medicaltest-assay',
    name: 'StudentMedicaltestAssay',
    component: () =>
      import('../views/pages/student/StudentMedicaltestAssay.vue')
  },
  {
    // 学生体检数据 - 常规信息
    path: '/student-medicaltest-health',
    name: 'StudentMedicaltestHealth',
    component: () =>
      import('../views/pages/student/StudentMedicaltestHealth.vue')
  },
  {
    // 学生考勤
    path: '/student-checkin-record',
    name: 'StudentCheckinRecord',
    component: () => import('../views/pages/student/StudentCheckinRecord.vue')
  },
  {
    // 特殊体检数据导入数据方法-鱼峰区小学
    path: '/student-medicaltest-special-import',
    name: 'StudentMedicaltestSpecialImport',
    component: () => import('../views/pages/student/StudentMedicaltestSpecialImport.vue')
  },
]
